// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromString(testEnv, stringEnv) {
  switch (stringEnv) {
    case "production" :
        return "Production";
    case "staging" :
        return "Staging";
    default:
      if (testEnv) {
        return "Test";
      } else {
        return "Development";
      }
  }
}

function stringToBoolean(x) {
  if (x === "true") {
    return true;
  } else {
    return false;
  }
}

var testEnv = stringToBoolean(process.env.RESCRIPT_TEST);

var env = fromString(testEnv, process.env.NODE_ENV);

var decoder = Json_Decode$JsonCombinators.map(Json_Decode$JsonCombinators.string, (function (extra) {
        return fromString(testEnv, extra);
      }));

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var zenlayerRedirectLoginUrl = Belt_Option.flatMap(Caml_option.nullable_to_opt(process.env.ZENLAYER_REDIRECT_LOGIN_URL), (function (x) {
        if (x === "") {
          return ;
        } else {
          return x;
        }
      }));

var zenlayerRedirectSignupUrl = Belt_Option.flatMap(Caml_option.nullable_to_opt(process.env.ZENLAYER_REDIRECT_SIGNUP_URL), (function (x) {
        if (x === "") {
          return ;
        } else {
          return x;
        }
      }));

var controlPlaneRedirectLoginUrl = Belt_Option.flatMap(Caml_option.nullable_to_opt(process.env.CONTROL_PLANE_REDIRECT_LOGIN_URL), (function (x) {
        if (x === "") {
          return ;
        } else {
          return x;
        }
      }));

export {
  fromString ,
  stringToBoolean ,
  testEnv ,
  env ,
  decoder ,
  fromJson ,
  zenlayerRedirectLoginUrl ,
  zenlayerRedirectSignupUrl ,
  controlPlaneRedirectLoginUrl ,
}
/* testEnv Not a pure module */

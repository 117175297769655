// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function toFetch(x) {
  if (typeof x !== "object") {
    return "GET";
  }
  var variant = x.NAME;
  if (variant === "Put") {
    return "PUT";
  } else if (variant === "Delete") {
    return "DELETE";
  } else if (variant === "Post") {
    return "POST";
  } else {
    return "PATCH";
  }
}

function toString(x) {
  if (typeof x !== "object") {
    return "GET";
  }
  var variant = x.NAME;
  if (variant === "Put") {
    return "PUT";
  } else if (variant === "Delete") {
    return "DELETE";
  } else if (variant === "Post") {
    return "POST";
  } else {
    return "PATCH";
  }
}

function body(x) {
  if (typeof x !== "object") {
    return ;
  }
  var variant = x.NAME;
  if (!(variant === "Post" || variant === "Delete")) {
    return Caml_option.some(x.VAL);
  }
  var body$1 = x.VAL;
  if (body$1 !== undefined) {
    return Caml_option.some(Caml_option.valFromOption(body$1));
  }
  
}

var Method = {
  toFetch: toFetch,
  toString: toString,
  body: body
};

function toString$1(x) {
  if (typeof x === "object") {
    return x._0;
  }
  switch (x) {
    case "Text" :
        return "text/plain";
    case "Json" :
        return "application/json";
    case "Blob" :
        return "application/download";
    
  }
}

function fromString(x) {
  if (Js_string.includes("application/json", x)) {
    return "Json";
  } else if (Js_string.includes("text/plain", x)) {
    return "Text";
  } else if (Js_string.includes("application/download", x)) {
    return "Blob";
  } else {
    return {
            TAG: "Other",
            _0: x
          };
  }
}

function fromHeaders(x) {
  return Belt_Option.map(Caml_option.nullable_to_opt(x.get("Content-Type")), (function (x) {
                return fromString(x);
              }));
}

var ContentType = {
  toString: toString$1,
  fromString: fromString,
  fromHeaders: fromHeaders
};

function toContentType(x) {
  var variant = x.NAME;
  if (variant === "Json") {
    return "Json";
  } else if (variant === "Text") {
    return "Text";
  } else {
    return "Blob";
  }
}

var $$Response = {
  toContentType: toContentType
};

function client(code) {
  if (code >= 400) {
    return code < 500;
  } else {
    return false;
  }
}

function server(code) {
  if (code >= 500) {
    return code < 600;
  } else {
    return false;
  }
}

var $$Error = {
  client: client,
  server: server
};

export {
  Method ,
  ContentType ,
  $$Response ,
  $$Error ,
}
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_promise from "rescript/lib/es6/js_promise.js";

function all(prim) {
  return Promise.all(prim);
}

function two(prim) {
  return Promise.all(prim);
}

function resolve(prim) {
  return Promise.resolve(prim);
}

function reject(prim) {
  return Promise.reject(prim);
}

function result(p) {
  var __x = (function (__x) {
        return Js_promise.then_((function (x) {
                      return Promise.resolve({
                                  TAG: "Ok",
                                  _0: x
                                });
                    }), __x);
      })(p);
  return Js_promise.$$catch((function (error) {
                return Promise.resolve({
                            TAG: "Error",
                            _0: error
                          });
              }), __x);
}

function andThen(p, fn) {
  return Js_promise.then_(fn, p);
}

function map(p, fn) {
  return Js_promise.then_((function (x) {
                return Promise.resolve(fn(x));
              }), p);
}

function mapOk(p, fn) {
  return Js_promise.then_((function (x) {
                if (x.TAG === "Ok") {
                  return Promise.resolve({
                              TAG: "Ok",
                              _0: fn(x._0)
                            });
                } else {
                  return Promise.resolve({
                              TAG: "Error",
                              _0: x._0
                            });
                }
              }), p);
}

function mapError(p, fn) {
  return Js_promise.then_((function (x) {
                if (x.TAG === "Ok") {
                  return Promise.resolve({
                              TAG: "Ok",
                              _0: x._0
                            });
                } else {
                  return Promise.resolve({
                              TAG: "Error",
                              _0: fn(x._0)
                            });
                }
              }), p);
}

function wait(p, fn) {
  ((function (__x) {
          return Js_promise.then_((function (x) {
                        return Promise.resolve(fn(x));
                      }), __x);
        })(p));
}

function $$catch(p, fn) {
  return Js_promise.$$catch(fn, p);
}

export {
  all ,
  two ,
  resolve ,
  reject ,
  result ,
  andThen ,
  map ,
  mapOk ,
  mapError ,
  wait ,
  $$catch ,
}
/* No side effect */

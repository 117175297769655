// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Env from "../libs/Env.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function token() {
  if (Env.env === "Test") {
    return "";
  } else {
    return Belt_Option.flatMap((function (__x) {
                    return Js_array.find((function (node) {
                                  if (!node.hasAttribute("name")) {
                                    return false;
                                  }
                                  var match = node.getAttribute("name");
                                  if ((match == null) || match !== "csrf-token") {
                                    return false;
                                  } else {
                                    return true;
                                  }
                                }), __x);
                  })(Array.prototype.slice.call(document.querySelectorAll("meta"))), (function (node) {
                  return Caml_option.nullable_to_opt(node.getAttribute("content"));
                }));
  }
}

export {
  token ,
}
/* Env Not a pure module */

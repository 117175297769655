// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Browser from "@sentry/browser";

var Scope = {};

function toString(x) {
  switch (x) {
    case "Info" :
        return "info";
    case "Warning" :
        return "warning";
    case "Error" :
        return "error";
    
  }
}

var Level = {
  toString: toString
};

function captureMessage(message, level) {
  Browser.captureMessage(message, toString(level));
}

export {
  Scope ,
  Level ,
  captureMessage ,
}
/* @sentry/browser Not a pure module */

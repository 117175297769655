// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Sentry from "../bindings/Sentry.res.js";
import * as Browser from "@sentry/browser";

function format(message, loc) {
  return "[" + loc.fullPath + "] " + message;
}

function setUser(user) {
  Browser.configureScope(function (scope) {
        scope.setUser(user);
      });
}

function warn(loc, message) {
  Sentry.captureMessage(format(message, loc), "Warning");
}

function warn1(loc, message, param) {
  var data1 = param[1];
  var label1 = param[0];
  Browser.withScope(function (scope) {
        scope.setExtra(label1, data1);
        Sentry.captureMessage(format(message, loc), "Warning");
      });
}

function warn2(loc, message, param, param$1) {
  var data2 = param$1[1];
  var label2 = param$1[0];
  var data1 = param[1];
  var label1 = param[0];
  Browser.withScope(function (scope) {
        scope.setExtra(label1, data1);
        scope.setExtra(label2, data2);
        Sentry.captureMessage(format(message, loc), "Warning");
      });
}

function warn3(loc, message, param, param$1, param$2) {
  var data3 = param$2[1];
  var label3 = param$2[0];
  var data2 = param$1[1];
  var label2 = param$1[0];
  var data1 = param[1];
  var label1 = param[0];
  Browser.withScope(function (scope) {
        scope.setExtra(label1, data1);
        scope.setExtra(label2, data2);
        scope.setExtra(label3, data3);
        Sentry.captureMessage(format(message, loc), "Warning");
      });
}

function warn4(loc, message, param, param$1, param$2, param$3) {
  var data4 = param$3[1];
  var label4 = param$3[0];
  var data3 = param$2[1];
  var label3 = param$2[0];
  var data2 = param$1[1];
  var label2 = param$1[0];
  var data1 = param[1];
  var label1 = param[0];
  Browser.withScope(function (scope) {
        scope.setExtra(label1, data1);
        scope.setExtra(label2, data2);
        scope.setExtra(label3, data3);
        scope.setExtra(label4, data4);
        Sentry.captureMessage(format(message, loc), "Warning");
      });
}

function warn5(loc, message, param, param$1, param$2, param$3, param$4) {
  var data5 = param$4[1];
  var label5 = param$4[0];
  var data4 = param$3[1];
  var label4 = param$3[0];
  var data3 = param$2[1];
  var label3 = param$2[0];
  var data2 = param$1[1];
  var label2 = param$1[0];
  var data1 = param[1];
  var label1 = param[0];
  Browser.withScope(function (scope) {
        scope.setExtra(label1, data1);
        scope.setExtra(label2, data2);
        scope.setExtra(label3, data3);
        scope.setExtra(label4, data4);
        scope.setExtra(label5, data5);
        Sentry.captureMessage(format(message, loc), "Warning");
      });
}

function warn6(loc, message, param, param$1, param$2, param$3, param$4, param$5) {
  var data6 = param$5[1];
  var label6 = param$5[0];
  var data5 = param$4[1];
  var label5 = param$4[0];
  var data4 = param$3[1];
  var label4 = param$3[0];
  var data3 = param$2[1];
  var label3 = param$2[0];
  var data2 = param$1[1];
  var label2 = param$1[0];
  var data1 = param[1];
  var label1 = param[0];
  Browser.withScope(function (scope) {
        scope.setExtra(label1, data1);
        scope.setExtra(label2, data2);
        scope.setExtra(label3, data3);
        scope.setExtra(label4, data4);
        scope.setExtra(label5, data5);
        scope.setExtra(label6, data6);
        Sentry.captureMessage(format(message, loc), "Warning");
      });
}

function warn7(loc, message, param, param$1, param$2, param$3, param$4, param$5, param$6) {
  var data7 = param$6[1];
  var label7 = param$6[0];
  var data6 = param$5[1];
  var label6 = param$5[0];
  var data5 = param$4[1];
  var label5 = param$4[0];
  var data4 = param$3[1];
  var label4 = param$3[0];
  var data3 = param$2[1];
  var label3 = param$2[0];
  var data2 = param$1[1];
  var label2 = param$1[0];
  var data1 = param[1];
  var label1 = param[0];
  Browser.withScope(function (scope) {
        scope.setExtra(label1, data1);
        scope.setExtra(label2, data2);
        scope.setExtra(label3, data3);
        scope.setExtra(label4, data4);
        scope.setExtra(label5, data5);
        scope.setExtra(label6, data6);
        scope.setExtra(label7, data7);
        Sentry.captureMessage(format(message, loc), "Warning");
      });
}

function error(loc, message) {
  Sentry.captureMessage(format(message, loc), "Error");
}

function error1(loc, message, param) {
  var data1 = param[1];
  var label1 = param[0];
  Browser.withScope(function (scope) {
        scope.setExtra(label1, data1);
        Sentry.captureMessage(format(message, loc), "Error");
      });
}

function error2(loc, message, param, param$1) {
  var data2 = param$1[1];
  var label2 = param$1[0];
  var data1 = param[1];
  var label1 = param[0];
  Browser.withScope(function (scope) {
        scope.setExtra(label1, data1);
        scope.setExtra(label2, data2);
        Sentry.captureMessage(format(message, loc), "Error");
      });
}

function error3(loc, message, param, param$1, param$2) {
  var data3 = param$2[1];
  var label3 = param$2[0];
  var data2 = param$1[1];
  var label2 = param$1[0];
  var data1 = param[1];
  var label1 = param[0];
  Browser.withScope(function (scope) {
        scope.setExtra(label1, data1);
        scope.setExtra(label2, data2);
        scope.setExtra(label3, data3);
        Sentry.captureMessage(format(message, loc), "Error");
      });
}

function error4(loc, message, param, param$1, param$2, param$3) {
  var data4 = param$3[1];
  var label4 = param$3[0];
  var data3 = param$2[1];
  var label3 = param$2[0];
  var data2 = param$1[1];
  var label2 = param$1[0];
  var data1 = param[1];
  var label1 = param[0];
  Browser.withScope(function (scope) {
        scope.setExtra(label1, data1);
        scope.setExtra(label2, data2);
        scope.setExtra(label3, data3);
        scope.setExtra(label4, data4);
        Sentry.captureMessage(format(message, loc), "Error");
      });
}

function error5(loc, message, param, param$1, param$2, param$3, param$4) {
  var data5 = param$4[1];
  var label5 = param$4[0];
  var data4 = param$3[1];
  var label4 = param$3[0];
  var data3 = param$2[1];
  var label3 = param$2[0];
  var data2 = param$1[1];
  var label2 = param$1[0];
  var data1 = param[1];
  var label1 = param[0];
  Browser.withScope(function (scope) {
        scope.setExtra(label1, data1);
        scope.setExtra(label2, data2);
        scope.setExtra(label3, data3);
        scope.setExtra(label4, data4);
        scope.setExtra(label5, data5);
        Sentry.captureMessage(format(message, loc), "Error");
      });
}

function error6(loc, message, param, param$1, param$2, param$3, param$4, param$5) {
  var data6 = param$5[1];
  var label6 = param$5[0];
  var data5 = param$4[1];
  var label5 = param$4[0];
  var data4 = param$3[1];
  var label4 = param$3[0];
  var data3 = param$2[1];
  var label3 = param$2[0];
  var data2 = param$1[1];
  var label2 = param$1[0];
  var data1 = param[1];
  var label1 = param[0];
  Browser.withScope(function (scope) {
        scope.setExtra(label1, data1);
        scope.setExtra(label2, data2);
        scope.setExtra(label3, data3);
        scope.setExtra(label4, data4);
        scope.setExtra(label5, data5);
        scope.setExtra(label6, data6);
        Sentry.captureMessage(format(message, loc), "Error");
      });
}

function error7(loc, message, param, param$1, param$2, param$3, param$4, param$5, param$6) {
  var data7 = param$6[1];
  var label7 = param$6[0];
  var data6 = param$5[1];
  var label6 = param$5[0];
  var data5 = param$4[1];
  var label5 = param$4[0];
  var data4 = param$3[1];
  var label4 = param$3[0];
  var data3 = param$2[1];
  var label3 = param$2[0];
  var data2 = param$1[1];
  var label2 = param$1[0];
  var data1 = param[1];
  var label1 = param[0];
  Browser.withScope(function (scope) {
        scope.setExtra(label1, data1);
        scope.setExtra(label2, data2);
        scope.setExtra(label3, data3);
        scope.setExtra(label4, data4);
        scope.setExtra(label5, data5);
        scope.setExtra(label6, data6);
        scope.setExtra(label7, data7);
        Sentry.captureMessage(format(message, loc), "Error");
      });
}

export {
  format ,
  setUser ,
  warn ,
  warn1 ,
  warn2 ,
  warn3 ,
  warn4 ,
  warn5 ,
  warn6 ,
  warn7 ,
  error ,
  error1 ,
  error2 ,
  error3 ,
  error4 ,
  error5 ,
  error6 ,
  error7 ,
}
/* Sentry Not a pure module */
